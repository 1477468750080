/* Provide sufficient contrast against white background */
body {
    background: #000000;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0;
}
a {
    color: #ffffff;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.mb-3, .my-3 {
    margin-bottom: 0rem !important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    border: solid 2px #e9eb1f;
}
@media (max-width: 1024px) {
    .text-light.nav-link {
        font-size:14px;
    }
}